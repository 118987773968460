import React from "react";

export function Logo(): JSX.Element {
  return (
    <svg width="114" height="24" viewBox="0 0 114 24" fill="none">
      <path
        d="M28.8776 18.3691C27.3817 16.8478 26.6211 14.9801 26.6211 12.7489C26.6211 10.5178 27.3817 8.64999 28.8776 7.14565C30.3735 5.62439 32.2835 4.86377 34.5654 4.86377C37.3206 4.86377 39.7968 6.2498 41.0983 8.48942L38.9263 9.74867C38.1234 8.25278 36.4754 7.29777 34.5654 7.29777C32.9343 7.29777 31.6328 7.82176 30.6186 8.83592C29.6213 9.85854 29.1227 11.1601 29.1227 12.7405C29.1227 14.3293 29.6213 15.6308 30.6186 16.645C31.6412 17.6676 32.9427 18.1832 34.5654 18.1832C36.4754 18.1832 38.1488 17.2282 38.9263 15.7323L41.0983 16.9662C40.4729 18.0733 39.5602 18.9607 38.3854 19.6368C37.236 20.2876 35.9514 20.6172 34.5654 20.6172C32.2835 20.6256 30.3735 19.865 28.8776 18.3691Z"
        fill="#1032AC"
      />
      <path
        d="M44.0152 18.9777C42.908 17.8705 42.3418 16.5268 42.3418 14.921C42.3418 13.3152 42.908 11.9715 44.0152 10.8643C45.1223 9.7572 46.4914 9.21631 48.0718 9.21631C49.6776 9.21631 51.0214 9.7572 52.1285 10.8643C53.2356 11.9715 53.8019 13.3152 53.8019 14.921C53.8019 16.5268 53.2356 17.8705 52.1285 18.9777C51.0214 20.0848 49.6776 20.6257 48.0718 20.6257C46.4914 20.6257 45.1223 20.0848 44.0152 18.9777ZM48.0718 18.3438C49.0268 18.3438 49.8297 18.0142 50.4805 17.3634C51.1312 16.7127 51.4608 15.8845 51.4608 14.9125C51.4608 13.9322 51.1397 13.1124 50.4805 12.4616C49.8297 11.8109 49.0268 11.4813 48.0718 11.4813C47.1168 11.4813 46.314 11.8109 45.6632 12.4616C45.0124 13.1124 44.6828 13.9406 44.6828 14.9125C44.6828 15.8929 45.0124 16.7127 45.6632 17.3634C46.314 18.0227 47.1168 18.3438 48.0718 18.3438Z"
        fill="#1032AC"
      />
      <path d="M55.873 20.3468V4.50049H58.2141V20.3468H55.873Z" fill="#1032AC" />
      <path
        d="M61.9312 18.9777C60.8241 17.8705 60.2578 16.5268 60.2578 14.921C60.2578 13.3152 60.8241 11.9715 61.9312 10.8643C63.0383 9.7572 64.4074 9.21631 65.9879 9.21631C67.5936 9.21631 68.9374 9.7572 70.0445 10.8643C71.1517 11.9715 71.7179 13.3152 71.7179 14.921C71.7179 16.5268 71.1517 17.8705 70.0445 18.9777C68.9374 20.0848 67.5936 20.6257 65.9879 20.6257C64.4074 20.6257 63.0383 20.0848 61.9312 18.9777ZM65.9879 18.3438C66.9429 18.3438 67.7457 18.0142 68.3965 17.3634C69.0473 16.7127 69.3769 15.8845 69.3769 14.9125C69.3769 13.9322 69.0557 13.1124 68.3965 12.4616C67.7457 11.8109 66.9429 11.4813 65.9879 11.4813C65.0328 11.4813 64.23 11.8109 63.5792 12.4616C62.9285 13.1124 62.5988 13.9406 62.5988 14.9125C62.5988 15.8929 62.9285 16.7127 63.5792 17.3634C64.23 18.0227 65.0328 18.3438 65.9879 18.3438Z"
        fill="#1032AC"
      />
      <path
        d="M76.1125 11.3124C76.6957 9.96859 77.8282 9.29248 79.4508 9.29248V11.8279C78.5634 11.7603 77.7774 11.98 77.1098 12.4787C76.4337 12.9519 76.1125 13.7633 76.1125 14.8619V20.33H73.7715V9.49531H76.1125V11.3124Z"
        fill="#1032AC"
      />
      <path d="M81.25 20.3298V4.4834H83.591V20.3298H81.25Z" fill="#1032AC" />
      <path
        d="M86.3392 7.41614C85.7645 6.81609 85.7645 5.92024 86.3392 5.34554C86.9139 4.7455 87.852 4.7455 88.4267 5.34554C89.0014 5.92024 89.0014 6.81609 88.4098 7.41614C87.8351 7.99083 86.9393 7.99083 86.3392 7.41614Z"
        fill="#1032AC"
      />
      <path d="M88.5539 9.49512H86.2129V20.3467H88.5539V9.49512Z" fill="#1032AC" />
      <path
        d="M101.145 13.6783V20.338H98.8036V13.9149C98.8036 12.2838 97.874 11.3964 96.3696 11.3964C94.7216 11.3964 93.6145 12.419 93.6145 14.5234V20.338H91.2734V9.49487H93.6145V10.8809C94.3328 9.77376 95.44 9.20752 96.9781 9.20752C99.4713 9.20752 101.145 10.9063 101.145 13.6783Z"
        fill="#1032AC"
      />
      <path
        d="M105.277 15.9181C105.624 17.5661 106.95 18.4789 108.751 18.4789C109.985 18.4789 110.923 18.0225 111.548 17.1351L113.483 18.2676C112.419 19.848 110.838 20.634 108.734 20.634C106.976 20.634 105.548 20.0931 104.457 19.0282C103.376 17.9464 102.826 16.5773 102.826 14.9293C102.826 13.2982 103.367 11.9544 104.432 10.8726C105.497 9.7655 106.883 9.22461 108.556 9.22461C110.137 9.22461 111.447 9.79085 112.461 10.898C113.475 12.0051 113.999 13.3742 113.999 14.9547C113.999 15.1913 113.974 15.5209 113.914 15.935H105.277V15.9181ZM111.633 14.0081C111.303 12.2502 110.069 11.3375 108.531 11.3375C106.798 11.3375 105.581 12.3601 105.252 14.0081H111.633Z"
        fill="#1032AC"
      />
      <path
        d="M8.82333 23.5583C8.20638 23.5583 7.59788 23.3977 7.04008 23.0765C5.95831 22.4596 5.29065 21.3693 5.23149 20.1439C4.15816 20.6256 2.94116 20.5665 1.90164 19.9664C0.735346 19.2988 0.0423316 18.1071 0.0338802 16.7633L7.46314e-05 8.26969C-0.00837676 6.95127 0.70154 5.71737 1.83403 5.05816L10.4206 0.0633854C10.5643 -0.0211285 10.7418 -0.0211285 10.8855 0.0633854L16.1253 3.04673C16.269 3.13124 16.362 3.28337 16.362 3.44394C16.362 3.61297 16.2775 3.76509 16.1338 3.84961L7.4373 8.92044C6.62597 9.39372 6.11888 10.2727 6.12733 11.2192L6.16114 18.5635L13.8773 14.0251C14.0209 13.9406 14.1984 13.9406 14.3505 14.0251L19.5397 17.0422C19.6834 17.1267 19.7679 17.2789 19.7679 17.4394C19.7679 17.6085 19.6834 17.7606 19.5397 17.8367L10.6319 23.0681C10.0657 23.3977 9.44028 23.5583 8.82333 23.5583ZM6.15269 19.6284V19.9664C6.15269 20.9299 6.65977 21.7919 7.49646 22.2736C8.33315 22.7554 9.33041 22.7469 10.1586 22.2652L18.3649 17.4394L14.097 14.9547L6.15269 19.6284ZM10.6573 1.00149L2.3073 5.86104C1.45371 6.35122 0.929728 7.28087 0.929728 8.26124L0.963533 16.7633C0.963533 17.7691 1.48752 18.6649 2.35801 19.1551C3.22851 19.6537 4.26803 19.6453 5.13007 19.1466L5.22303 19.0959L5.18923 11.2108C5.18078 9.93461 5.86534 8.75142 6.96402 8.10911L14.959 3.45239L10.6573 1.00149Z"
        fill="#1032AC"
      />
    </svg>
  );
}
