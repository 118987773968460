export const Paths = {
  Home: "/",
  Faq: "/faq",
  SignIn: "/sign-in",
  SignUp: "/sign-up",
  PrivacyPolicy: "/privacy-policy",
  TermsOfUse: "/terms-of-use",
  Contact: "/contact",
  DesktopApp: "/desktop-app",
};
