import * as R from "ramda";

export const isEmptyOrNil = R.either(R.isNil, R.isEmpty);
export const getBodyMessage = R.pathOr("Something went wrong, please try again later", ["body", "message"]);

function random(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

export function generateGuestToken(): string {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  return Array.from(Array(random(15, 25)), () => charset.charAt(random(0, charset.length))).join("");
}

const RegexConstructor = R.construct(RegExp);
// @ts-ignore
const newRegexWithGlobalFlag = R.call(RegexConstructor, R.__, "g");
const getNormalizedPathAsRegex = R.pipe<string, string, RegExp>(
  R.ifElse(R.startsWith("/"), R.drop(1), R.identity),
  newRegexWithGlobalFlag
);

export const pathnameMatches = (path: string): ((x: string) => boolean) =>
  R.pipe(R.match(getNormalizedPathAsRegex(path)), R.isEmpty, R.not);

export function tryAutomaticDownload(url: string): void {
  try {
    if (typeof window !== "undefined") {
      const element = document.createElement("a");
      element.setAttribute("href", url);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  } catch {
    console.error("Could not automatically download file");
  }
}
