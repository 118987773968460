import { api } from "../utils/api";

type ContactInput = { name: string; email: string; subject: string; message: string };

export async function sendContactForm(input: ContactInput, recaptchaToken: string): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return api.request<any>("/contact", {
    requiresAuthentication: false,
    method: "POST",
    body: { ...input, recaptcha_response_token: recaptchaToken },
  });
}
