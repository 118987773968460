import { always, cond, has, prop, propEq, T } from "ramda";
import styled from "styled-components";

import { Colors } from "../utils/style-utils";

export interface TypographyProps {
  bold?: boolean;
  underline?: boolean;
  upper?: boolean;
  lower?: boolean;
  muted?: boolean;
  error?: boolean;
}

const defaultProps = `
  font-family: 'Neue Haas', sans-serif;
  font-style: normal;
`;

export const H1 = styled.h1<TypographyProps>`
  ${defaultProps}
  font-size: 48px;
  line-height: 1.1;
  color: ${({ color }) => color || "black"};
  font-weight: ${({ bold }) => (bold ? "700" : "normal")};
`;

export const H2 = styled.h2<TypographyProps>`
  ${defaultProps}
  font-size: 36px;
  color: ${({ color }) => color || "black"};
  font-weight: ${({ bold }) => (bold ? "700" : "normal")};
`;

export const H3 = styled.h3<TypographyProps>`
  ${defaultProps}
  font-size: 32px;
  color: ${({ color }) => color || "black"};
  font-weight: ${({ bold }) => (bold ? "700" : "normal")};
`;

export const H4 = styled.h4<TypographyProps>`
  ${defaultProps}
  font-size: 24px;
  color: ${({ color }) => color || "black"};
  font-weight: ${({ bold }) => (bold ? "700" : "normal")};
`;

export const H5 = styled.h5<TypographyProps>`
  ${defaultProps}
  font-size: 20px;
  color: ${({ color }) => color || "black"};
  font-weight: ${({ bold }) => (bold ? "700" : "normal")};
`;

// @ts-ignore
const getTextTransform: (a: TypographyProps) => string = cond([
  [propEq("upper", true), always("uppercase")],
  [propEq("lower", true), always("lowercase")],
  [T, always("none")],
]);

// @ts-ignore
const getTextColor: (a: TypographyProps) => string = cond([
  [has("color"), prop("color")],
  [propEq("error", true), always(Colors.Red)],
  [propEq("muted", true), always(Colors.Grey)],
  [T, always("black")],
]);

export const Typography = styled.p<TypographyProps>`
  font-family: "Neue Haas", sans-serif;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "700" : "normal")};
  font-size: inherit;
  color: ${getTextColor};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  text-transform: ${getTextTransform};
`;

Typography.defaultProps = {
  bold: false,
  underline: false,
  muted: false,
};
