import { navigate } from "gatsby";
import { always, cond, curry, equals, ifElse, not, pipe, T } from "ramda";
import Drawer from "rc-drawer";
import React, { FC, useEffect, useState } from "react";

import { useLocation } from "@reach/router";

import { ContactForm } from "../forms/ContactForm";
import { Paths } from "../paths";
import { pathnameMatches } from "../utils/function-utils";
import { useMobileDetect } from "../hooks/use-mobile-detect";
import { Link } from "./Link";

enum Sidebar {
  None,
  Contact,
}

const matchesTerms = pathnameMatches(Paths.TermsOfUse);
const matchesPolicy = pathnameMatches(Paths.PrivacyPolicy);
const getOpenSidebar = pipe(equals(Sidebar.None), not);
const closeOrOpenSelf = curry((sidebar, current) =>
  ifElse(equals(sidebar), always(Sidebar.None), always(sidebar))(current)
);
const matchesContact = pathnameMatches(Paths.Contact);
// @ts-ignore
const getSidebarFromLocation = cond<string, number>([
  [matchesContact, always(Sidebar.Contact)],
  [T, always(Sidebar.None)],
]);

export const Footer: FC = () => {
  const location = useLocation();
  const { isMobile } = useMobileDetect();
  const [sidebar, setSidebar] = useState(Sidebar.None);
  const open = getOpenSidebar(sidebar);

  useEffect(() => {
    if (location.pathname === Paths.Home) return;
    const locationSidebar = getSidebarFromLocation(location.pathname);
    const next = closeOrOpenSelf(locationSidebar, sidebar);
    setSidebar(next);
  }, [location]);

  function onSidebarClose() {
    setSidebar(Sidebar.None);
  }

  function afterVisibleChange(open: boolean) {
    if (!open && sidebar === Sidebar.None) navigate(Paths.Home);
  }

  return (
    <>
      <div className="bg-transparent relative w-full flex flex-row mt-auto justify-center sm:justify-end py-4 sm:py-8 px-4 sm:px-12">
        <Link muted active={matchesContact(location.pathname)} className="mr-10" to={Paths.Contact}>
          Contact
        </Link>
        <Link muted active={matchesPolicy(location.pathname)} className="mr-10" to={Paths.PrivacyPolicy}>
          Privacy Policy
        </Link>
        <Link muted active={matchesTerms(location.pathname)} to={Paths.TermsOfUse}>
          Terms of Use
        </Link>
      </div>

      <Drawer
        handler={false}
        className="sidebar"
        width={isMobile() ? "100vw" : 480}
        open={open}
        placement="right"
        onClose={onSidebarClose}
        onHandleClick={onSidebarClose}
        afterVisibleChange={afterVisibleChange}
        level={null}
      >
        <ContactForm onSuccess={onSidebarClose} />
      </Drawer>
    </>
  );
};
