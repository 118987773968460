import { AxiosRequestConfig } from "axios";
import { stringify } from "qs";

import { Config } from "../config";
import { Client, ClientPayload } from "./client";
import { LocalStorage } from "./storage";

if (process.env.NODE_ENV === "development" || !process.env.NODE_ENV) {
  require("dotenv").config();
}

export function createAuthorizationHeader(token: string): { Authorization: string } {
  return { Authorization: `Bearer ${token}` };
}

export function createUrl(uri: string, baseUrl: string, query?: Record<string, string>): string {
  let url = uri[0] !== "/" ? uri : uri.slice(1);
  const domainUrl = baseUrl[baseUrl.length - 1] !== "/" ? baseUrl : baseUrl.slice(0, baseUrl.length - 1);

  if (query) {
    url = `${url}?${stringify(query)}`;
  }

  return `${domainUrl}/${url}`;
}

type ApiOptions = {
  requiresAuthentication?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  headers?: Record<string, string>;
  method?: AxiosRequestConfig["method"];
  queryParams?: Record<string, string>;
};

export class Api {
  private storage = new LocalStorage();
  private client: Client;

  private accessTokenKey = Config.AccessTokenKey;
  private refreshTokenKey = Config.RefreshTokenKey;
  private authUrl = Config.AuthApiUrl;
  private baseUrl = Config.BaseApiUrl;

  constructor() {
    this.client = new Client(this.storage);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async request<T = ClientPayload>(
    uri: string,
    { requiresAuthentication, body, headers = {}, method = "get", queryParams }: ApiOptions = {} as ApiOptions
  ) {
    const url = createUrl(uri, requiresAuthentication ? this.baseUrl : this.authUrl, queryParams);

    let request;

    if (requiresAuthentication && !headers.authorization) {
      const tokens = await this.getToken();

      request = this.client.request(url, method, body, {
        ...createAuthorizationHeader(tokens.accessToken),
        ...headers,
      });
    } else {
      request = this.client.request(url, method, body, headers);
    }

    const data = await request;

    return (data as unknown) as T;
  }

  async getToken(): Promise<{ accessToken: string; refreshToken?: string }> {
    let refreshToken;

    try {
      const accessToken = await this.storage.getItem<string>(this.accessTokenKey);

      if (accessToken === null) {
        throw new Error("InvalidAccessToken");
      }

      try {
        refreshToken = await this.storage.getItem<string>(this.refreshTokenKey);

        if (refreshToken === null) {
          throw new Error("InvalidRefreshToken");
        }
      } catch (error) {
        return {
          accessToken,
        };
      }

      return {
        accessToken,
        refreshToken,
      };
    } catch (error) {
      return Promise.reject();
    }
  }

  removeToken(): Promise<[void, void]> {
    return Promise.all([this.storage.removeItem(this.accessTokenKey), this.storage.removeItem(this.refreshTokenKey)]);
  }

  async refreshToken(): Promise<{
    accessToken: string;
    refreshToken: string;
  }> {
    const { refreshToken } = await this.getToken();

    try {
      const token = await this.request<{
        accessToken: string;
        refreshToken: string;
      }>("/token/refresh", {
        body: { refreshToken },
        method: "post",
      });

      await this.setToken({ token });

      return token;
    } catch (error) {
      return Promise.reject();
    }
  }

  async setToken({
    token,
  }: {
    token: {
      accessToken: string;
      refreshToken: string;
    };
  }): Promise<{
    accessToken: string;
    refreshToken: string;
  }> {
    await this.storage.setItem(this.accessTokenKey, token.accessToken);

    if (token.refreshToken) {
      await this.storage.setItem(this.refreshTokenKey, token.refreshToken);
    }

    return token;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async setItem(key: string, value: any): Promise<void> {
    try {
      await this.storage.setItem(key, JSON.stringify(value));

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getItem(key: string): Promise<any> {
    return this.storage.getItem(key);
  }
}

const api = new Api();

export { api };
