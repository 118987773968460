import React, { FC, forwardRef, InputHTMLAttributes } from "react";
import { useUID } from "react-uid";
import styled from "styled-components";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  ref?: ((instance: HTMLInputElement | null) => void) | React.MutableRefObject<HTMLInputElement | null> | null;
  fullWidth?: boolean;
  error?: boolean;
}

const TextInput = styled.input<InputProps>`
  padding: 20px 30px;
  background: ${({ error }) => (error ? "rgba(214, 87, 69, 0.05)" : "rgba(245, 245, 245, 0.6)")};
  border: 1px solid ${({ error }) => (error ? "#D65745" : "#ebebeb")};
  box-sizing: border-box;
  border-radius: 6px;
  color: black;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  font-family: "Neue Haas", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #606060;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #606060;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #606060;
  }
`;

export const Input: FC<InputProps> = forwardRef((props, ref) => {
  const uid = useUID();
  return <TextInput ref={ref} id={uid} {...props} />;
});

Input.defaultProps = {
  fullWidth: false,
};
