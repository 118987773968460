import { anyPass, equals } from "ramda";
import React, { FC } from "react";
import styled from "styled-components";

import { useLocation } from "@reach/router";

import { Paths } from "../paths";
import { Footer } from "./Footer";
import { Header } from "./Header";

const shape = require("../images/background.svg");

const Background = styled.div<{ homepage?: boolean }>`
  background-color: transparent;
  background-image: ${({ homepage }) => (homepage ? `url(${shape})` : "none")};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
`;

const LayoutContainer = styled.div`
  min-height: 100vh;
  background-color: #f5f5f5;
`;

const isHomepage = anyPass([
  equals(Paths.Contact),
  equals(Paths.Home),
  equals(Paths.SignIn),
  equals(Paths.SignUp),
  equals("/404"),
]);

export const Layout: FC<{ isAuthenticated?: boolean; withBg?: boolean }> = ({ children, withBg, isAuthenticated }) => {
  const location = useLocation();

  return (
    <LayoutContainer className="flex flex-col">
      <Header isAuthenticated={isAuthenticated} />
      <Background homepage={isHomepage(location.pathname) || withBg} className="flex flex-col justify-center">
        {children}
        <Footer />
      </Background>
    </LayoutContainer>
  );
};

Layout.defaultProps = {
  withBg: false,
};
