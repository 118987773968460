import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import Recaptcha from "react-recaptcha";
import { toast } from "react-toastify";

import { ErrorMessage } from "@hookform/error-message";

import { sendContactForm } from "../api/contact";
import { Button } from "../components/Button";
import { Input } from "../components/Input";
import { Textarea } from "../components/Textarea";
import { Typography } from "../components/Typography";
import { Config } from "../config";
import { ErrorIcon } from "../icons/ErrorIcon";
import { Container } from "./styles";

export interface P {
  onSuccess?: () => void;
}

type FormValues = { name: string; email: string; subject: string; message: string };

export const ContactForm: FC<P> = ({ onSuccess }) => {
  const { handleSubmit, register, errors } = useForm<FormValues>();
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setToken] = useState("");

  const onSubmit = async (values: FormValues) => {
    if (!recaptchaToken) {
      toast.warn("Recaptcha is not valid");
      return;
    }

    setLoading(true);
    try {
      await sendContactForm(values, recaptchaToken);
      toast.success("Contact form sent!");
      onSuccess && onSuccess();
    } catch (err) {
      console.error(err);
      toast.error(
        <span className="flex flex-row">
          <ErrorIcon className="mr-2" />
          {err.body.message || "An error ocurred, try again later"}
        </span>
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <Container className="flex flex-col flex-1 bg-white w-full">
        <Input
          placeholder="Name"
          className={!!errors.name ? "mb-2" : "mb-4"}
          name="name"
          error={!!errors.name}
          ref={register({ required: "Name is required" })}
        />

        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => (
            <div className="flex flex-row justify-end mb-4">
              <Typography error style={{ fontSize: 14 }}>
                {message}
              </Typography>
            </div>
          )}
        />

        <Input
          placeholder="Email"
          className={!!errors.email ? "mb-2" : "mb-4"}
          name="email"
          error={!!errors.email}
          ref={register({
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Email is invalid",
            },
          })}
        />

        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <div className="flex flex-row justify-end mb-4">
              <Typography error style={{ fontSize: 14 }}>
                {message}
              </Typography>
            </div>
          )}
        />

        <Input
          placeholder="Subject"
          className={!!errors.subject ? "mb-2" : "mb-4"}
          name="subject"
          error={!!errors.subject}
          ref={register({ required: "Subject is required" })}
        />

        <ErrorMessage
          errors={errors}
          name="subject"
          render={({ message }) => (
            <div className="flex flex-row justify-end mb-4">
              <Typography error style={{ fontSize: 14 }}>
                {message}
              </Typography>
            </div>
          )}
        />

        <Textarea
          placeholder="Message"
          className={!!errors.message ? "mb-2" : "mb-4"}
          name="message"
          error={!!errors.message}
          ref={register({ required: "Message is required" })}
        />

        <ErrorMessage
          errors={errors}
          name="message"
          render={({ message }) => (
            <div className="flex flex-row justify-end mb-4">
              <Typography error style={{ fontSize: 14 }}>
                {message}
              </Typography>
            </div>
          )}
        />

        <Recaptcha
          sitekey={Config.RecaptchaKey}
          render="explicit"
          expiredCallback={() => setToken("")}
          verifyCallback={(response = "") => setToken(response)}
        />

        <div className="flex mt-4">
          <Button type="submit" disabled={loading} fullWidth>
            Submit
          </Button>
        </div>
      </Container>
    </form>
  );
};
