import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { Config } from "../config";
import { LocalStorage } from "./storage";

export type SyntheticError = { error: { response: AxiosResponse }; status: number };

export type ClientPayload = {
  body: AxiosResponse["data"];
  headers: AxiosResponse["headers"];
  status: AxiosResponse["status"];
};

export class Client {
  private baseUrl = Config.BaseApiUrl;
  private authUrl = Config.AuthApiUrl;
  private accessTokenKey = "access_token";
  private refreshTokenKey = "refresh_token";
  private storage = new LocalStorage();

  constructor(storage?: LocalStorage) {
    if (storage) this.storage = storage;
    axios.defaults.baseURL = this.baseUrl;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    // @ts-ignore
    axios.interceptors.response.use(null, async (error) => {
      if (error.config && error.response && error.response.status === 401) {
        if (error.config.data && error.config.data.includes("refreshToken")) {
          return Promise.reject(error);
        }

        const refreshToken = await this.storage.getItem(this.refreshTokenKey);

        let token: {
          refreshToken?: string;
          accessToken?: string;
        } = {};

        const request = await axios({
          method: "post",
          data: { refreshToken },
          responseType: "json",
          url: "token/refresh",
          baseURL: this.authUrl,
        });

        token = request.data as { refreshToken: string; accessToken: string };

        await this.storage.setItem(this.accessTokenKey, token.accessToken);

        if (token.refreshToken) {
          this.storage.setItem(this.refreshTokenKey, token.refreshToken);
        }

        // eslint-disable-next-line no-param-reassign
        error.config.headers.Authorization = `Bearer ${token.accessToken}`;
        return axios.request(error.config);
      }

      return Promise.reject(error);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async request(
    url: string,
    method: AxiosRequestConfig["method"],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    data: any,
    headers: Record<string, string>
  ): Promise<ClientPayload> {
    const options: AxiosRequestConfig = {
      data,
      headers,
      method,
      responseType: "json",
      url,
    };

    try {
      const response = await axios(options);

      return Promise.resolve(this.getResponse(response));
    } catch (error) {
      if (!error.json) {
        return Promise.reject(this.getResponse({ error, status: -1 }));
      }

      const errorText = error.text();

      return Promise.reject(this.getResponse(error, errorText));
    }
  }

  getResponse(response: AxiosResponse | SyntheticError, text = ""): ClientPayload {
    if (response && (response as SyntheticError).error) {
      const { error } = response as SyntheticError;

      if (!error.response) {
        return {
          body: "Network error",
          headers: {},
          status: -1,
        };
      }

      return {
        body: error.response.data,
        headers: error.response.headers,
        status: error.response.status,
      };
    }

    return {
      body: response ? (response as AxiosResponse).data : text,
      headers: response ? (response as AxiosResponse).headers : {},
      status: response ? response.status : 200,
    };
  }
}
