import styled from "styled-components";

export const Container = styled.div`
  padding: 114px 48px 48px;
`;

export const Loading = styled.div<{ fullSize?: boolean }>`
  position: ${({ fullSize }) => (fullSize ? "fixed" : "absolute")};
  background-color: ${({ fullSize }) => (fullSize ? "#f5f5f5" : "white")};
  z-index: 90;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
