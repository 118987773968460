import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

import { Colors } from "../utils/style-utils";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: JSX.Element | string;
  fullWidth?: boolean;
  link?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  padding: ${({ link }) => (link ? 0 : 20)}px;

  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  height: ${({ link }) => (link ? "auto" : "56px")};
  line-height: 14px;

  background: ${({ link }) => (link ? "transparent" : Colors.Black)};
  border-radius: ${({ link }) => (link ? 0 : 6)}px;

  color: ${({ link }) => (link ? Colors.Black : "white")};
  font-family: "Neue Haas", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s background ease-in-out, 0.3s color ease-in-out;

  &:hover {
    color: ${({ link }) => (link ? Colors.Blue : "white")};
    background: ${({ link }) => (link ? "transparent" : Colors.Blue)};
    transition: 0.3s background ease-in-out, 0.3s color ease-in-out;
  }

  &[disabled],
  &[disabled]:hover {
    color: ${({ link }) => (link ? Colors.Black : "white")};
    background: ${({ link }) => (link ? "transparent" : Colors.Grey)};
    transition: 0.3s background ease-in-out, 0.3s color ease-in-out;
  }
`;

Button.defaultProps = {
  type: "button",
  disabled: false,
  link: false,
};
