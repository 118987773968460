import { always, cond, propEq, T } from "ramda";
import styled from "styled-components";

import { Colors } from "../utils/style-utils";

export interface AnchorProps {
  bold?: boolean;
  underline?: boolean;
  upper?: boolean;
  lower?: boolean;
  muted?: boolean;
  active?: boolean;
}

// @ts-ignore
const getTextTransform: (a: AnchorProps) => string = cond([
  [propEq("upper", true), always("uppercase")],
  [propEq("lower", true), always("lowercase")],
  [T, always("none")],
]);

export const Anchor = styled.a<AnchorProps>`
  font-family: "Neue Haas", sans-serif;
  color: ${({ muted, active }) => (active ? (muted ? Colors.Black : Colors.Blue) : muted ? Colors.Grey : Colors.Black)};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  font-weight: ${({ bold }) => (bold ? "700" : "normal")};
  transition: 0.3s color ease-in-out;
  text-transform: ${getTextTransform};
  font-size: inherit;

  &:hover {
    color: ${({ muted }) => (muted ? Colors.Black : Colors.Blue)};
    transition: 0.3s color ease-in-out;
  }
`;

Anchor.defaultProps = {
  bold: false,
  underline: false,
  muted: false,
};
