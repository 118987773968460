import React, { FC } from "react";
import styled from "styled-components";
import classd from "classd";
import { useScrollYPosition } from "../hooks/use-scroll-position";

import { Paths } from "../paths";
import { Anchor } from "./Anchor";
import { Link } from "./Link";
import { Logo } from "./Logo";

const Wrapper = styled.div`
  background-color: transparent;
  box-shadow: none;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &.solid {
    background-color: #f5f5f5;
    box-shadow: 0 16px 40px rgb(35 45 82 / 8%);
    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
`;

export const Header: FC<{ isAuthenticated?: boolean }> = () => {
  const y = useScrollYPosition();

  return (
    <Wrapper
      className={classd`fixed z-50 top-0 left-0 right-0 flex flex-col sm:flex-row ${{
        solid: y >= 100,
      }}`}
    >
      <div className="flex sm:justify-start justify-center py-4 sm:py-8 px-4 sm:px-12">
        <Link to={Paths.Home}>
          <Logo />
        </Link>
      </div>

      <div style={{ zIndex: 10000 }} className="sm:ml-auto flex flex-row justify-between py-4 sm:py-8 px-12">
        <Link className="mr-6" to={Paths.Home + "#how-it-works"}>
          How It Works
        </Link>
        <Anchor
          className="mr-6"
          href="https://s3.amazonaws.com/colorline.io/Colorline_doc_final+file.pdf"
          target="_new"
          download
        >
          Sample Document
        </Anchor>
        <Link to={Paths.DesktopApp}>Desktop Program</Link>
      </div>
    </Wrapper>
  );
};
